import React from 'react';

import Layout from '../components/layout';
import { H1, H4, P } from '../components/typography';
import SEO from '../components/seo';
import { ImagePartyGirl } from '../components/image';
import { ImageConcertBackground } from '../components/image2';
import {ImageCardboardApp} from '../components/image3';
import LinkToAppStore from '../images/app-comingsoon.svg'

const IndexPage = () => (
    <Layout>
      <SEO title="Home"/>
      <section className="section" style={{ paddingTop: `7rem`}}  id="wasistdas">
        <div className="container">
          <div className="content-illustration" style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'flex-end',
          }}>
            <ImagePartyGirl style={{
              maskImage: `linear-gradient(rgba(0, 0, 0, 1.0), transparent)`,
              zIndex: -10,
            }}/>
            <ImageConcertBackground style={{
              position: 'absolute',
              top: -155,
              width: '150%',
              zIndex: -11,
              left: 0,
            }}/>
          </div>
          <div className="content-body">
            <H1>
              Auf der Bühne mit deinen Stars – hautnah!
            </H1>
            <P>
              An den Live-Konzerten deiner Lieblingsband kämpfst du dich mühsam bis
              in die Hälfte der Zuschauermassen vor. Deinen Bandliebling siehst du
              maximal in Miniaturgrösse oder über den Screen mit Live-Übertragung. Du
              hast genug davon und möchtest viel lieber bei deinen Stars auf der
              Bühne stehen?
            </P>
            <P emphasis>Dann lade jetzt die App herunter und geniesse deine Lieblingssongs mit
              Virtual Reality direkt mit deinen Stars auf der Bühne.</P>
            <LinkToAppStore/>
          </div>
        </div>
      </section>
      <section className="section" id="sofunktionierts">
        <div className="container">
          <div className="content-illustration" style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
          }}>
            <ImageCardboardApp style={{
              width: '100%',
              maskImage: `linear-gradient(rgba(0, 0, 0, 1.0), transparent)`,
              zIndex: -10
            }}/>
          </div>
          <div className="content-body">
            <H1>
              So funktioniert’s …
            </H1>
            <P>
              Mit der Live on Stage 360 App rockst du gemeinsam mit deinen Lieblingsbands die virtuelle Bühne.
            </P>
            <div className="instruction">
              <div className="instruction-content">
                <H4>APP Installieren und starten</H4>
                <P>Suche im App Store oder bei Google Play nach der App Live on Stage 360. Oder noch einfacher: Klicke hier direkt auf die Verlinkung. Sobald die App installiert ist, kannst du sie starten.</P>
              </div>
            </div>
            <div className="instruction">
              <div className="instruction-content">
                <H4>Smartphone in VR-Brille einlegen und ab auf die Bühne!</H4>
                <P>Lege dein Smartphone in die VR-Brille ein und los geht dein VR Erlebnis. Wähle den Song, mit dem du die Bühne rocken willst und lade ihn herunter. Bist du bereit für den Auftritt? Dann los, klicke auf Play. Viel Spass!</P>
              </div>
            </div>
            <LinkToAppStore/>
          </div>
        </div>
      </section>
      <section className="section" id="nochfragen">
        <div className="container">
          <div className="content">
            <H1>
              Hast du noch Fragen?
            </H1>
            <P>
              Dann hau in die Tasten. Wir freuen uns auch über Feedbacks und Anregungen.
            </P>
            <div className="contact">
              <div className="contact-form">
                <form action="https://formspree.io/info@interpunkt.ch" method="POST" className="form">
                  <div className="form-group">
                    <label htmlFor="aufnahmen">Aufnahmen</label>
                    <div className="form-control form-control--checkbox">
                      <input type="checkbox" name={"aufnahmen"}/>
                      <span>Du möchtest deinen Event oder Auftritt mit Virtual Reality aufzeichnen? Gerne erstellen wir dir eine Offerte.</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Name</label>
                    <input type="text" name={"lastName"} className="form-control" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName">Vorname</label>
                    <input type="text" name={"firstName"} className="form-control" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">E-Mail</label>
                    <input type="email" name={"email"} className="form-control" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="comments">Bemerkung</label>
                    <textarea name="comments" className="form-control" rows="10"/>
                  </div>
                  <div className="form-group">
                    <label></label>
                    <div className="form-control">
                      <button type={"submit"}>absenden</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="contact-infos">
                <div className="contact-info-box">
                  <div className="contact-info-box__content">
                    <H4>Kontakt</H4>
                    <span>interpunkt.ag</span>
                    <span>Web & Werbeagentur</span>
                    <span>Metzgergasse 6b</span>
                    <span>5034 Suhr</span>
                    <span>Telefon <a href="tel:+41625313232">062 531 32 32</a></span>
                    <span>E-Mail <a href="mailto:info@interpunkt.ch">info@interpunkt.ch</a></span>
                    <a href="/datenschutzrichtlinie">Datenschutzrichtlinien</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
);

export default IndexPage;
